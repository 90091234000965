import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../../Store/Redux/store";
import { Trans, t } from "@lingui/macro";
import styles from "./styles.module.scss";
import { FloorMapObject } from "../../../../Store/floorMapDuck/models";
import { selectResourceById } from "../../../../../App/Store/Resources/locationsResourcesDuck";
import Box from "../../../../../Components/Box";
import deskIcon from "../../../../../App/Pages/CreateNewBooking/Desk/DeskDetails/assets/desk-icon.png";
import Text from "../../../../../Components/Text";
import getDisplayedResourceName from "../../../../../Functions/resourseNameParsing";
import Divider from "../../../../../Components/Divider";

import floorImage from './assets/floor.png';
import sectionImage from './assets/section.png';


interface DeskInterface extends FloorMapObject {
  bookingId?: string;
  userName?: string;
}

interface Props {
  desk?: DeskInterface;
}

export function DeskDetails({ desk }: Props) {
  const {
    adminFloorMap,
    adminReservations,
    locations: locationsDuck,
    locationsResources,
  } = useTypedSelector(state => state);

  const { floorId, locationId } = useParams<{ floorId: string; locationId: string }>();
  const { sections } = adminFloorMap;

  const deskSection = desk?.parentId ? sections[desk.parentId] : undefined;
  const userName = desk?.userName ? desk?.userName : '-';
  const selectedFloor = locationsDuck.floors.find(floor => floor.id === floorId);

  const resourcesArray = desk ? Object.keys(desk.resourceIds).map((resourceId) => selectResourceById({ state: locationsResources, locationId, resourceId })) : [];

  const booking = adminReservations.mapViewBookings.find(b => b.id === desk?.bookingId);

  return (
    <Box>
      <Box alignItems="center" display="flex" marginBottom={10}>
        <Box alignItems="center" display="flex">
          <Box
            alignItems="center"
            backgroundColor="#F4F4F4"
            borderRadius={8}
            display="flex"
            height={30}
            justifyContent="center"
            marginEnd={10}
            width={30}
          >
            <img alt={t`desk icon`} src={deskIcon} width={16} />
          </Box>

          <Text weight="semi-bold">
            {desk ? desk.name : t`Desk is not selected`}
          </Text>
        </Box>
      </Box>

      <Divider />

      {desk &&
        <>
          <Box alignItems="center" display="flex" justifyContent="between" marginTop={7}>
            <Box alignItems="center" display="flex" marginTop={8}>
              <Text weight="semi-bold">
                <Trans>Details</Trans>
              </Text>
            </Box>
          </Box>

          <Box className={styles.items}>
            {selectedFloor &&
              <Box className={styles.item}>
                <img alt={t`Floor Image`} height={16} src={floorImage} />
                <Text color="gray" size="md">
                  {selectedFloor.floorName}
                </Text>
              </Box>
            }

            {deskSection &&
              <Box className={styles.item}>
                <img alt={t`Section Image`} height={16} src={sectionImage} />
                <Text color="gray" size="md">{deskSection.name}</Text>
              </Box>
            }
          </Box>

          <Box className={styles.deskStatus}>
            <div className={styles.deskStatusRow}>
              <div>
                <Trans>
                  User:
                </Trans>
              </div>

              <div>
                {userName}
              </div>
            </div>

            <div className={styles.deskStatusRow}>
              <div>
                <Trans>
                  Status:
                </Trans>
              </div>

              <div className={styles.statusColor}>
                <Trans>
                  Booked
                </Trans>
              </div>
            </div>

            {booking?.parkingSpot &&
              <div className={styles.deskStatusRow}>
                <div>
                  <Trans>
                    Parking Spot:
                  </Trans>
                </div>

                <div>
                  <Trans>
                    {booking.parkingSpot.floor ? `Floor ${booking.parkingSpot.floor}, ` : ''}{booking.parkingSpot.name}
                  </Trans>
                </div>
              </div>
            }
          </Box>

          <Divider />

          <Box marginTop={27}>
            <Text weight="semi-bold">
              <Trans>Amenities</Trans>
            </Text>

            <Box className={styles.items}>
              {resourcesArray.length ? (
                resourcesArray.map((resource, index) => {
                  return resource ? (
                    <Box className={styles.item} key={resource.name + index}>
                      <Text color="gray" size="md">
                        {getDisplayedResourceName(resource.name)}
                      </Text>
                    </Box>
                  ) : null;
                })
              ) : (
                <Box margin={5}>
                  <Text>
                    -
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </>
      }
    </Box>
  );
}
