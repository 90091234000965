import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Box from 'Components/Box';
import deskIcon from './assets/desk-icon.png';
import IconButton from '@material-ui/core/IconButton';
import { LocationOutlinedIcon } from '../../../../Components/Icons';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { addDeskToSavedList, isDeskSaved, removeDeskFromSavedList } from 'App/Store/Desk/deskDuck';
import { FloorMapObject } from 'Admin/Store/floorMapDuck/models';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'Store/Redux/store';
import getDisplayedResourceName from '../../../../../Functions/resourseNameParsing';
import {isAnotherUserSelectedByExecutiveAssistant} from "../../../../Store/Users/executiveAssistant/helpers";

interface Props {
  desk?: FloorMapObject;
}

export function DeskDetails({ desk }: Props) {
  const dispatch = useDispatch();
  const {
    availableDesks,
    adminFloorMap,
    config,
    createNewBooking,
    desk: deskDuck,
    locations: locationsDuck,
    profile,
    executiveAssistant,
  } = useTypedSelector(state => state);
  const { floorId, locationId = '' } = createNewBooking;
  const { sections } = adminFloorMap;
  const deskSection = desk?.parentId ? sections[desk.parentId] : undefined;
  const selectedFloor = locationsDuck.floors.find(floor => floor.id === floorId);
  const selectedLocation = locationsDuck.locations.find(location => location.id === locationId);
  const anotherUserSelectedByExecutiveAssistant = isAnotherUserSelectedByExecutiveAssistant(profile, executiveAssistant);
  const isFavorite = typeof desk?.id === 'string' ? isDeskSaved(deskDuck, desk.id) : false;
  const showSaveDesk = !anotherUserSelectedByExecutiveAssistant;
  const selectedDesk = desk ? availableDesks?.availableDesks?.[desk.id] : undefined;

  const handleBookmarkClick = (deskId: string) => {
    if (isFavorite) {
      dispatch(removeDeskFromSavedList(deskId, profile.id));
    } else {
      if (floorId && locationId) {
        dispatch(addDeskToSavedList({
          deskId,
          floorId,
          locationId,
        }));
      }
    }
  };

  return (
    <Box>
      <Box alignItems="center" display="flex" marginBottom={10}>
        <Box alignItems="center" display="flex">
          <Box
            alignItems="center"
            backgroundColor="#F4F4F4"
            borderRadius={8}
            display="flex"
            height={30}
            justifyContent="center"
            marginEnd={10}
            width={30}
          >
            <img alt={t`desk icon`} src={deskIcon} width={16} />
          </Box>

          <Text weight="semi-bold">
            <div data-testid="bookingItemDeskName">
              {desk ? desk.name : t`Desk is not selected`}
            </div>
          </Text>
        </Box>
      </Box>

      {desk &&
        <>
          <Box alignItems="center" display="flex" justifyContent="between" marginTop={7}>
            <Text weight="semi-bold">
              <Trans>Details</Trans>
            </Text>

            {showSaveDesk &&
              <Box alignItems="center" display="flex" marginTop={8}>
                <Text color="blue" size="md" weight="semi-bold">
                  <Trans>Save</Trans>
                </Text>

                <IconButton onClick={() => typeof desk.id === 'string' && handleBookmarkClick(desk.id)} size="small">
                  {isFavorite ? (
                    <BookmarkIcon data-testid="bookingItemIconSaved" style={{ fontSize: 20, color: config.theme.primary }} />
                  ) : (
                    <BookmarkBorderOutlinedIcon data-testid="bookingItemIconNonSaved" style={{ fontSize: 20, color: config.theme.primary }} />
                  )}
                </IconButton>
              </Box>
            }
          </Box>

          <Box className={styles.items}>
            {deskSection &&
              <Box className={styles.item} dataTestId="bookingItemSection">
                <Text color="gray" size="md">{deskSection.name}</Text>
              </Box>
            }

            {selectedFloor &&
              <Box className={styles.item} dataTestId="bookingItemFloor">
                <Text color="gray" size="md">
                  {selectedFloor.floorName}
                </Text>
              </Box>
            }
          </Box>

          <Box
            dataTestId="bookingItemLocation"
            display="flex"
            left={-1}
            marginTop={11}
            position="relative"
            style={{ whiteSpace: 'initial' }}
          >
            <LocationOutlinedIcon />

            <Text color='gray' size="md">
              {selectedLocation?.locationAddress} | {selectedLocation?.region}
            </Text>
          </Box>

          <Box marginTop={27}>
            <Text weight="semi-bold">
              <Trans>Amenities</Trans>
            </Text>

            <Box className={styles.items} dataTestId="bookingItemResources">
              {selectedDesk?.resources?.length ? (
                selectedDesk?.resources?.map((resource, index) => {
                  return resource ? (
                    <Box className={styles.item} key={resource.name + index}>
                      <Text color="gray" size="md">
                        {getDisplayedResourceName(resource.name)}
                      </Text>
                    </Box>
                  ) : null;
                })
              ) : (
                <Box margin={5}>
                  <Text>
                    -
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </>
      }
    </Box>
  );
}